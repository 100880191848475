<template>
    <ion-page>
        <ion-content padding class="page-new-patient">
            <div
                class="page-new-patient__container d-flex flex-column ion-justify-content-center"
            >
                <div
                    class="page-new-patient__title"
                    v-show="step !== WizardStep.QR_CODE"
                >
                    {{ $t("newPatient.requestNewLink") }}
                </div>
                <div class="step-content">
                    <component
                        :is="stepComponent"
                        :registerModel="registerModel"
                        :backToHome="
                            step === WizardStep.THANKYOU ? true : undefined
                        "
                        @change-step="changeStep"
                        @go-next="goNext"
                    />
                </div>
                <div
                    class="text-center mt-3 fw-bold back-to-btn mb-4"
                    v-show="step !== WizardStep.THANKYOU"
                >
                    <button
                        type="button"
                        class="btn btn-link fw-bold bg-transparent"
                        @click="cancelRequest()"
                    >
                        {{ $t("newPatient.cancelRequest") }}
                    </button>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./NewPatient.ts"></script>
<style lang="scss" src="./NewPatient.scss"></style>
