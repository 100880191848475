import { Vue, Options } from 'vue-class-component';
import StepActivationType from '../Register/WizardItems/StepActivationType/StepActivationType.vue';
import TransferManagement from '../Register/WizardItems/TransferManagement/TransferManagement.vue';
import StepRequestorRelationship from '../Register/WizardItems/StepRequestorRelationship/StepRequestorRelationship.vue';
import StepThankyou from '../Register/WizardItems/StepThankyou/StepThankyou.vue';
import { ActivationType, PatientPlace, WizardStep } from '../Register/models/RegisterEnums';
import { DefaultRegisterModel, RegisterModel } from '../Register/models/RegisterModel';
import associationClient from '@/core/api/association-service';
import {
    Identity,
    IPatientTransferRequest,
    IRelativeAssociationRequest,
    PatientTransferRequest,
    RelativeAssociationRequest,
} from '@/../client-generator/generated-clients/api-clients';
import { message } from '@/core/utilities/utilities';
import { SET_PROFILE } from '@/core/store/types/action-types';
import meClient from '@/core/api/me-service';
import { mapState } from 'vuex';
import transferClient from '@/core/api/transfer-service';
import { alertController } from '@ionic/vue';

@Options({
    name: 'NewPatient',
    components: {
        StepActivationType,
        TransferManagement,
        StepRequestorRelationship,
        StepThankyou,
    },
    watch: {
        '$route.query.type'() {
            this.queryUpdated();
        },
    },
    computed: {
        ...mapState({ profile: 'profile' }),
    },
})
export default class NewPatient extends Vue {
    profile!: Identity;
    WizardStep = WizardStep;
    step: WizardStep = WizardStep.ACTIVATION_TYPE;
    message = message;

    registerModel: RegisterModel = JSON.parse(JSON.stringify(DefaultRegisterModel));

    ionViewWillEnter() {
        this.registerModel = JSON.parse(JSON.stringify(DefaultRegisterModel));
        this.registerModel.requestor.email = this.profile.email ?? '';
        this.registerModel.tempData.patientPlace = '';
        this.step = WizardStep.ACTIVATION_TYPE;
        this.queryUpdated();
    }

    queryUpdated() {
        if (!this.$route.query.type) {
            this.registerModel.tempData.activationType = '';
        } else if (this.$route.query.type === 'new') {
            this.registerModel.tempData.activationType = ActivationType.NEW;
        } else {
            this.registerModel.tempData.activationType = ActivationType.TRANSFER;
        }
    }

    get stepComponent() {
        let stepName = '';
        switch (this.step) {
            case WizardStep.ACTIVATION_TYPE:
                stepName = 'StepActivationType';
                break;
            case WizardStep.QR_CODE:
                stepName = 'TransferManagement';
                break;
            case WizardStep.OVERVIEW:
            case WizardStep.REQUESTOR_RELATIONSHIP:
                stepName = 'StepRequestorRelationship';
                break;
            case WizardStep.THANKYOU:
                stepName = 'StepThankyou';
                break;
        }
        return stepName;
    }

    changeStep(newStep: WizardStep) {
        this.step = newStep;
    }

    cancelRequest() {
        this.$router.replace({ name: 'page-relatives-list' });
    }

    async goNext() {
        this.step++;
        if (this.step === WizardStep.OVERVIEW) {
            try {
                if (this.registerModel.tempData.activationType === ActivationType.NEW) {
                    const association: IRelativeAssociationRequest = {
                        relation: this.registerModel.patient.relation as any,
                        patientFirstName: this.registerModel.patient.firstName,
                        patientLastName: this.registerModel.patient.lastName,
                        patientFiscalCode: this.registerModel.patient.fiscalCode,
                        confirmClosestRelative: this.registerModel.confirmClosestRelative,
                        confirmPersonalData: this.registerModel.confirmPersonalData,
                        confirmEmail: this.registerModel.confirmEmail,
                        confirmPrivacy: this.registerModel.confirmPrivacy,
                        isEmergencyRoom: this.registerModel.tempData.patientPlace === PatientPlace.EMERGENCY_ROOM,
                    };
                    await associationClient.association(new RelativeAssociationRequest(association));
                } else {
                    const request: IPatientTransferRequest = {
                        token: this.registerModel.tempData.transferCode,
                        relation: this.registerModel.patient.relation as any,
                        confirmClosestRelative: this.registerModel.confirmClosestRelative,
                        confirmPersonalData: this.registerModel.confirmPersonalData,
                        confirmEmail: this.registerModel.confirmEmail,
                        confirmPrivacy: this.registerModel.confirmPrivacy,
                    };
                    await transferClient.transfer(new PatientTransferRequest(request));
                }
                const profile = await meClient.me();
                this.$store.dispatch(SET_PROFILE, profile);
                this.step++;
            } catch (error) {
                this.step--;
                if (error.status === 400) {
                    let message = '';
                    if (
                        error.response.nonFieldErrors.includes('fiscal-code-no-match-with-name') ||
                        error.response.nonFieldErrors.includes('fiscal-code-no-match-with-surname')
                    ) {
                        message = this.$t('fiscalCodeNoMatchWithName');
                    } else if (error.response.nonFieldErrors.includes('fiscal-code-not-valid')) {
                        message = this.$t('invalidFiscalCode');
                    } else {
                        message = this.$t('backendValidationError');
                    }
                    const alert = await alertController.create({
                        header: this.$t('warning'),
                        message: message,
                        buttons: [this.$t('ok')],
                    });
                    return alert.present();
                } else {
                    this.message(this.$t('apiError'));
                }
            }
        }
    }
}
